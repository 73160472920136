<app-profile-percent-success-msg [familyProfileDetails]="childCharacteristicPreferencesDetails"
    [successMsg]="familyProfileSuccessMsg"></app-profile-percent-success-msg>

<div #errors>
    <dfps-form-validation [shouldCheckDirty]="true" [validationErrors]="validationErrors"
        [validationErrorsLength]="validationErrorsLength">
    </dfps-form-validation>
</div>

<app-family-child-char-special-needs-hobbies></app-family-child-char-special-needs-hobbies>

<div class="row pl-2">
    <div class="d-flex col-sm-12 justify-content-center">
        <p-progressSpinner *ngIf="requestProcessing"></p-progressSpinner>
    </div>
</div>

<form *ngIf="childCharacteristicPreferencesForm" [formGroup]="childCharacteristicPreferencesForm"
    (ngSubmit)="onSaveChildCharacteristicPreferences()">
    <div class="row pl-3 pt-4">
        <h2 class="col-sm-12 ">{{childCharacteristicPreferencesForm.get('characteristicName').value}} </h2>
        <div class="col-sm-12">
            <hr aria-hidden="true" />
        </div>
    </div>
    <ng-template [ngIf]="characteristicType.code != 'HB'">
        <div class="row pl-5 pt-3">
            <div class="col-sm-12 col-lg-10 d-flex">
                <label for="overallRating" class="col-sm-4 col-form-label col-form-label-sm requiredDisplay">
                    {{childCharacteristicPreferencesForm.get('characteristicName').value }} Overall:</label>
                <dfps-select _id="overallRating"
                    ariaLabel="{{childCharacteristicPreferencesForm.get('characteristicName').value}} Overall"
                    formControlName="overallRating" aria-required="true"
                    [dropDownValues]="childCharacteristicPreferencesLookup$ ? childCharacteristicPreferencesLookup$['severity']:[]">
                </dfps-select>
            </div>
        </div>

        <div class="row pl-5 pt-3">
            <div class="col-sm-12">
                <label for="summaryInformation"
                    class="col-sm-12 col-form-label col-form-label-sm">{{childCharacteristicPreferencesForm.get('characteristicName').value}}
                    Summary Information:</label>
                <dfps-textarea _id="summaryInformation" class="col-sm-12 p-0" formControlName="summaryInformation"
                    name="summaryInformation"
                    ariaLabel="{{childCharacteristicPreferencesForm.get('characteristicName').value}} Summary Information"
                    maxLength="4000" (focusout)="onTrimSpace('summaryInformation')"></dfps-textarea>
            </div>
        </div>

        <div class="row pl-4 pt-3">
            <div class="col-sm-12">
                <hr aria-hidden="true" />
            </div>
        </div>
    </ng-template>

    <ng-container formArrayName="characteristicDetails">
        <div class="form-row pl-5">
            <ng-template ngFor let-item
                [ngForOf]="childCharacteristicPreferencesForm.get('characteristicDetails')['controls']" let-i="index">
                <div class="d-flex pt-3" [ngClass]="characteristicType.code != 'HB' ?'col-sm-12 col-lg-10':'col-sm-6'"
                    *ngIf="childCharacteristicPreferencesForm.get('characteristicDetails')['controls'][i].controls.childCharacteristicPreferences.value"
                    [formGroupName]="i">
                    <ng-container *ngIf="characteristicType.code != 'HB' else displayhobbiesSection">
                        <label
                            [attr.for]="childCharacteristicPreferencesForm.get('characteristicDetails')['controls'][i].controls.childCharacteristicPreferences.value"
                            class="col-sm-4 col-form-label col-form-label-sm notranslate">
                            {{childCharacteristicPreferencesForm.get('characteristicDetails')['controls'][i].controls.childCharacteristicPreferencesLabel.value}}:
                        </label>
                        <dfps-select
                            _id="{{childCharacteristicPreferencesForm.get('characteristicDetails')['controls'][i].controls.childCharacteristicPreferences.value}}"
                            formControlName="severity"
                            [dropDownValues]="(this.characteristicType.code==='DV' &&
                            childCharacteristicPreferencesForm.get('characteristicDetails')['controls'][i].controls.childCharacteristicPreferences.value==='40') ||
                             (this.characteristicType.code==='MD' &&
                            childCharacteristicPreferencesForm.get('characteristicDetails')['controls'][i].controls.childCharacteristicPreferences.value==='145')?
                            childCharacteristicPreferencesLookup$ ? childCharacteristicPreferencesLookup$['yesno']:[] : childCharacteristicPreferencesLookup$ ? childCharacteristicPreferencesLookup$['severity']:[]">
                        </dfps-select>
                    </ng-container>
                    <ng-template #displayhobbiesSection>
                        <fieldset>
                            <legend class="sr-only">
                                {{childCharacteristicPreferencesForm.get('characteristicName').value}}
                            </legend>
                            <dfps-checkbox _id="{{item.controls.childCharacteristicPreferences.value}}"
                                class="notranslate"
                                ariaLabel="{{item.controls.childCharacteristicPreferencesLabel.value}}"
                                label="{{item.controls.childCharacteristicPreferencesLabel.value}}"
                                [formControl]="item.controls['severity']" inputName="severity">
                            </dfps-checkbox>
                        </fieldset>
                    </ng-template>
                </div>
            </ng-template>
        </div>
    </ng-container>

    <div class="row pl-4">
        <div class="d-flex col-sm-12 pt-3 justify-content-end">
            <dfps-button type="submit" size="small" label="Save Changes" _id="saveChildCharacteristicPreferences"
                [disabled]="requestProcessing"></dfps-button>
        </div>
    </div>
</form>