import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { AuthTareService } from '@shared/services/auth-tare.service';
import { DataShareService } from '@shared/services/data-share.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router,
    private authService: MsalService,
    private authTareService: AuthTareService,
    private shareService: DataShareService) {
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const userLoggedIn = this.authService.instance.getAllAccounts();

    if (userLoggedIn) {
      if (route.data?.allowedRoles?.some(r => this.authTareService.userAuthorities()?.includes(r))
        && this.shareService.getDataFromMap('familyProfileInfo')?.familyId) {
        return true;
      }
    }
    this.authTareService.setRedirectUrl(state.url.split('?')[0], route.queryParams);
    this.router.navigate(['/logon']);
    return false;
  }

}
