<ng-template #showPageLoading>
    <div class="row pl-5">
        <div class="d-flex col-sm-10 justify-content-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</ng-template>
<div class="container-fluid bodycolor" *ngIf="specialNeedsDetails">
    <form [formGroup]="specialNeedsForm">
        <ng-template [ngIf]="characteristicsType.code !== 'HB'">
            <div class="row pl-3 pt-4">
                <h2 class="col-sm-12 ">Characteristics</h2>
                <div class="col-sm-12">
                    <hr aria-hidden="true" />
                </div>
            </div>
            <div class="row pl-3 pt-4">
                <p class="col-sm-12 boldBody">
                    *For more information on each Special Needs category, see the
                    <strong><a href="/Adoption_and_Foster_Care/About_Our_Children/Disabilities/levels.asp">Special Needs
                            Descriptions</a></strong>
                </p>
            </div>
            <div class="row pl-5">
                <div class="col-sm-12 d-flex" *ngFor="let details of specialNeedsDetails">
                    <ng-template [ngIf]="details.characteristicType!='HB'">
                        <p class="col-sm-7 col-lg-5 col-xl-5 boldBody pr-0 mb-0 d-inline-flex">
                            <strong>{{getCharacteristicDecode(details.characteristicType)}} Overall:</strong>
                        </p>
                        <p class="col-sm-5 col-lg-6 boldBody mb-0 d-inline-flex">
                            {{severityMap.get(details.overallRating)}}
                        </p>
                    </ng-template>
                </div>
            </div>

            <div class="row pl-3 pt-4">
                <h2 class="col-sm-12 ">Medical</h2>
                <div class="col-sm-12">
                    <hr aria-hidden="true" />
                </div>
            </div>

            <div class="row pl-5 pt-3">
                <div class="col-sm-12 d-flex" *ngFor="let details of characteristicDetails">
                    <p class="col-sm-7 col-lg-5 col-xl-5 boldBody pr-0 mb-0 d-inline-flex">
                        <strong>{{details.childCharacteristicPreferencesLabel}}:</strong>
                    </p>
                    <p class="col-sm-5 col-lg-6 boldBody mb-0 d-inline-flex">
                        {{severityMap.get(details.severity)}}
                    </p>
                </div>
            </div>
        </ng-template>

        <ng-template [ngIf]="characteristicsType.code === 'HB'">
            <div class="row pl-3 pt-4">
                <h2 class="col-sm-12 ">Interest/Hobbies</h2>
                <div class="col-sm-12">
                    <hr aria-hidden="true" />
                </div>
            </div>

            <fieldset>
                <div class="row pl-3 pt-4">
                    <div class="d-flex col-sm-12 pt-2">
                        <dfps-multi-select id="{{hobbies}}" [multiSelectValues]="hobbies" formControlName="hobbies"
                            inputName="hobbies" [preSelectedValues]="filteredHobbies" ariaLabel="{{hobbies}}"
                            [multiSelectDisabled]="disableHobbies">
                        </dfps-multi-select>
                    </div>
                </div>
            </fieldset>
        </ng-template>
    </form>
</div>